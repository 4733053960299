<template>
  <div id="foundation-title-transfer">
    <v-overlay :value="ProgressLoadingFlag">
      <v-progress-circular indeterminate size="64"></v-progress-circular>
    </v-overlay>
    <v-toolbar color="#8950FC">
      <v-toolbar-title>
        <h1 class="text-white">{{ pageTitle }}</h1>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <v-btn icon dark @click="closePrompt">
          <v-icon>mdi-close</v-icon>
        </v-btn>
      </v-toolbar-items>
    </v-toolbar>
    <v-card>
      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container>
          <v-form
            ref="form1"
            v-model="valid1"
            lazy-validation
            v-on:submit.prevent="searchForm"
          >
            <v-row wrap>
              <v-col cols="12" sm="6" lg="6" md="6" id="step11">
                <h6 class="mycard-text1 fsize-3">Upload MRF</h6>
                <v-file-input
                  :clearable="false"
                  truncate-length="25"
                  accept="application/pdf"
                  placeholder="Upload pdf"
                  v-model="UploadedFile1"
                  prepend-icon="mdi-file-pdf"
                >
                  <template v-slot:append-outer>
                    <v-icon color="red" @click="UploadedFile1 = {}">
                      mdi-delete
                    </v-icon>
                  </template>
                </v-file-input>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" sm="12" lg="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="confirmAlert"
                  color="#8950FC"
                  elevation="30"
                  shaped
                  tile
                  large
                  class="
                    btn btn-primary
                    font-size-h6
                    px-15
                    py-4
                    my-3
                    mr-3
                    white--text
                  "
                  >Upload</v-btn
                >
              </v-col>
            </v-row>
            <v-row wrap v-if="ProgressLoadingFlag">
              <v-col align="center" cols="12" md="12">
                <v-progress-circular
                  :rotate="180"
                  :size="100"
                  :width="15"
                  :value="uploadPercentage"
                  color="#00a4ef"
                >
                  {{ uploadPercentage }}
                </v-progress-circular>
                <h3 class="red--text">
                  Please wait, we are uploading your details. Time may vary
                  based on your internet connection.
                </h3>
              </v-col>
            </v-row>
          </v-form>
          <hr />
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {},
  props: {
    recordData: {
      required: true,
    },
    pageTitle: {
      type: String,
    },
  },
  data() {
    return {
      valid1: false,
      ProgressLoadingFlag: false,
      LoadingFlag: false,
      SearchFlag: false,
      TransferMemberFlag: false,
      row: {},

      UploadedFile1: {},
      uploadPercentage: 0,

      MembershipIdRules: [(v) => !!v || "Membership Id is required"],
      MembershipId: "",
      ZoneCode: "",
      LomCode: "",
    };
  },
  computed: {},
  watch: {},
  created() {
    // this.initialize()
  },
  methods: {
    closePrompt() {
      console.log("closePrompt called");
      this.$emit("hideDialog");
    },
    resetForm() {
      console.log("resetForm called");

      (this.row = []), (this.MembershipId = "");
      this.TransferMemberFlag = false;
    },
    refreshPageData() {
      console.log("refreshPageData called");
      var recordData = this.recordData;
      console.log("recordData=" + JSON.stringify(recordData));
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        this.SearchFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/members/search";
        var upload = {
          UserInterface: 2,
          MembershipId: this.MembershipId,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.SearchFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.row = records;
              thisIns.TransferMemberFlag = true;
              thisIns.toast("success", output);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.SearchFlag = false;
          });
      }
    },
    confirmAlert() {
      console.log("confirmAlert is called");
      var htmlTxt = "";

      Swal.fire({
        title: "Do you want to continue?",
        text: "Please verify all the information before proceeding.",
        icon: "warning",
        html: htmlTxt,
        showCancelButton: true,
        confirmButtonText: `Continue`,
        confirmButtonColor: "#3085d6",
        cancelButtonText: `Cancel`,
        cancelButtonColor: "#d33",
      }).then((result) => {
        /* Read more about isConfirmed, isDenied below */
        if (result.isConfirmed) {
          this.submitForm();
        } else {
          this.sweetAlert("error", "You cancelled the process", true);
        }
      });
    },
    submitForm() {
      console.log("submitForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);
      if (validate1) {
        this.ProgressLoadingFlag = true;

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/upload/monthly-report";

        console.log("upload=" + JSON.stringify(this.recordData));

        let upload = new FormData();
        upload.append("UserInterface", 1);
        upload.append("Year", this.recordData.YearCode);
        upload.append("Month", this.recordData.MonthCode);
        upload.append("Zone", this.recordData.ZoneCode);
        upload.append("Lom", this.recordData.LomCode);
        upload.append("UploadedFile1", this.UploadedFile1);
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = {};
        var table = {};
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.ProgressLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            if (flag == 1) {
              thisIns.sweetAlert("success", output, true);
              thisIns.closePrompt();
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.ProgressLoadingFlag = false;
            thisIns.sweetAlert("error", error, false);
          });
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

#foundation-title-transfer {
  .preview-content {
    font-size: 14px;
    font-weight: normal;
    line-height: 1.5em;
  }
}
</style>