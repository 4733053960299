<template>
  <div id="mrf-report">
    <v-overlay :value="OverlayLoadingFlag">
      <div class="text-center loading-text">
        <p>{{ OverlayLoadingText }}</p>
        <p></p>
        <v-progress-circular indeterminate size="64"></v-progress-circular>
      </div>
    </v-overlay>
    <v-card>
      <v-card-title class="text-h5">
        <h2>{{ PageTitle }}</h2>
      </v-card-title>

      <v-card-subtitle class="text-h5">
        <h4>{{ PageDescription }}</h4>
      </v-card-subtitle>

      <v-dialog eager v-model="previewRecordPrompt" v-if="previewRecordPrompt">
        <pdf-document-file-preview
          :PageTitle="PreviewPageTitle"
          :PreviewButtonFlag="previewRecordPrompt"
          :Documentpath="DocumentFilePath"
          @hideDialog="hidePreviewRecordPrompt"
          v-if="previewRecordPrompt"
        ></pdf-document-file-preview>
      </v-dialog>

      <v-dialog eager v-model="UploadMrfFlag" v-if="UploadMrfFlag" persistent>
        <upload-manual-mrf
          :pageTitle="UploadMrfPageTitle"
          :recordData="MrfDetail"
          @hideDialog="hideUploadMrfPrompt"
          v-if="UploadMrfFlag"
        ></upload-manual-mrf>
      </v-dialog>

      <v-card-text>
        <div class="d-flex justify-content-center mb-3" v-if="LoadingFlag">
          <b-spinner
            variant="primary"
            type="grow"
            label="Loading..."
          ></b-spinner>
        </div>
        <v-container class="py-0">
          <v-form ref="form1" v-model="valid1" lazy-validation id="createForm">
            <v-row wrap>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Area</h6>
                </label>
                <v-autocomplete
                  @change="getZoneCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="AreaCodeOptionsLoading"
                  :items="AreaCodeOptions"
                  :rules="AreaCodeRules"
                  v-model="AreaCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Zone</h6>
                </label>
                <v-autocomplete
                  @change="getRegionCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="ZoneCodeOptionsLoading"
                  :items="ZoneCodeOptions"
                  :rules="ZoneCodeRules"
                  v-model="ZoneCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Region
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  @change="getLomCodeOptions"
                  :reduce="(option) => option.value"
                  :loading="RegionCodeOptionsLoading"
                  :items="RegionCodeOptions"
                  :rules="RegionCodeRules"
                  v-model="RegionCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6>
                    Lom
                    <small>(Optional)</small>
                  </h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="LomCodeOptionsLoading"
                  :items="LomCodeOptions"
                  v-model="LomCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
              <v-col cols="12" md="3">
                <label>
                  <h6><span class="text-danger">*</span> Month</h6>
                </label>
                <v-autocomplete
                  :reduce="(option) => option.value"
                  :loading="MonthCodeOptionsLoading"
                  :items="MonthCodeOptions"
                  :rules="MonthCodeRules"
                  v-model="MonthCode"
                  dense
                  clearable
                  required
                  outlined
                ></v-autocomplete>
              </v-col>
            </v-row>
            <v-row wrap>
              <v-col align="center" cols="12" md="12">
                <v-btn
                  :disabled="!valid1"
                  @click.prevent="searchForm"
                  color="#ff7f50"
                  class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                  >Search</v-btn
                >
              </v-col>
            </v-row>
          </v-form>
        </v-container>
        <v-container class="py-0" v-if="tableData1.length > 0">
          <v-row wrap v-if="LomCode > 0">
            <v-col align="center" cols="12" md="12">
              <v-btn
                v-if="GenerateMrfButtonFlag"
                :disabled="!valid1"
                @click.prevent="generateManualMrf"
                color="#8a2be2"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Generate Manual MRF</v-btn
              >
              <v-btn
                v-if="UploadMrfButtonFlag"
                :disabled="!valid1"
                @click.prevent="updateLomMrf"
                color="#008b8b"
                class="btn btn-primary font-size-h6 px-15 py-4 my-3 mr-3 white--text"
                >Upload MRF</v-btn
              >
            </v-col>
          </v-row>
          <h3>LO List</h3>
          <br />
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <h4>{{ tableData1.length }} Records found.</h4>
            </v-col>
          </v-row>
          <v-row wrap>
            <v-col align="center" cols="12" md="12">
              <v-text-field
                v-model="search"
                append-icon="mdi-magnify"
                label="Search"
                single-line
                hide-details
              ></v-text-field>
              <br />
              <v-data-table
                class="elevation-1"
                v-model="selected"
                :search="search"
                :item-key="tableOptions1.ItemKey"
                :single-select="tableOptions1.SingleSelectFlag"
                :headers="tableColumns1"
                :items="tableData1"
                :items-per-page="tableOptions1.ItemsPerPage"
                :footer-props="tableOptions1.FooterProps"
              >
                <template v-slot:[`item.FileGenerateFlag`]="{ index, item }">
                  <v-tooltip
                    bottom
                    v-if="item.FileGenerateFlag && item.Total > 0"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.Total > 0"
                        @click="generateReport(index, item)"
                        target="_blank"
                        color="red"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-pdf</v-icon>Generate
                      </v-btn>
                    </template>
                    <span>Generate {{ item.MonthName }} MRF</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.FilePreviewFlag`]="{ item }">
                  <v-tooltip
                    bottom
                    v-if="item.FilePreviewFlag && item.FilePath != ''"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.FilePath != '' && item.FilePreviewFlag == 1"
                        @click="previewData(item, e)"
                        target="_blank"
                        color="blue"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-file-search</v-icon>Preview
                      </v-btn>
                    </template>
                    <span>{{ item.MonthName }} MRF Preview</span>
                  </v-tooltip>
                  <br />
                  <small>{{ item.LastUpdatedAtTxt }}</small>
                </template>
                <template v-slot:[`item.FileDownloadFlag`]="{ item }">
                  <v-tooltip
                    bottom
                    v-if="item.FileDownloadFlag && item.FilePath != ''"
                  >
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        v-if="item.FilePath != '' && item.FileDownloadFlag == 1"
                        :href="item.FilePath"
                        target="_blank"
                        color="#2fb65a"
                        small
                        class="text-white"
                        v-bind="attrs"
                        v-on="on"
                      >
                        <v-icon dark>mdi-download</v-icon>Download
                      </v-btn>
                    </template>
                    <span>{{ item.MonthName }} MRF Download</span>
                  </v-tooltip>
                </template>
                <template v-slot:[`item.actions`]="{ item }">
                  <v-tooltip bottom v-if="item.AppreciationFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="success"
                        @click="sendEmailAlert(1, item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-shield-check
                      </v-icon>
                    </template>
                    <span> Send Appreciation E-mail </span>
                  </v-tooltip>
                  <v-tooltip bottom v-if="item.RemainderFlag">
                    <template v-slot:activator="{ on, attrs }">
                      <v-icon
                        icon
                        size="25px"
                        color="warning"
                        @click="sendEmailAlert(2, item)"
                        v-bind="attrs"
                        v-on="on"
                      >
                        mdi-alarm
                      </v-icon>
                    </template>
                    <span> Send Remainder E-mail </span>
                  </v-tooltip>
                </template>
              </v-data-table>
            </v-col>
          </v-row>
        </v-container>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import common from "@/view/Common.vue";
import companyConfig from "@/company_config.json";
import PdfDocumentFilePreview from "@/view/components/PdfDocumentFilePreview.vue";
import UploadManualMrf from "@/view/pages/erp/reports/UploadManualMrf.vue";
import Swal from "sweetalert2";

export default {
  mixins: [common],
  components: {
    PdfDocumentFilePreview,
    UploadManualMrf,
  },
  data() {
    return {
      LoadingFlag: false,
      uploadPercentage: 0,
      previewRecordPrompt: false,

      GenerateMrfButtonFlag: false,
      UploadMrfButtonFlag: false,

      MrfDetail: {},
      UploadMrfFlag: false,
      UploadMrfPageTitle: "",

      PageTitle: "",
      PageDescription: "",

      alert: {},
      selected: {},

      valid1: true,
      OverlayLoadingFlag: false,
      OverlayLoadingText: "",

      JciYearCode: "",

      AreaCodeRules: [(v) => !!v || "Area is required"],
      AreaCode: "",
      AreaCodeOptions: [],
      AreaCodeOptionsLoading: false,

      ZoneCodeRules: [(v) => !!v || "Zone is required"],
      ZoneCode: "",
      ZoneCodeOptions: [],
      ZoneCodeOptionsLoading: false,

      RegionCodeRules: [],
      RegionCode: "",
      RegionCodeOptions: [],
      RegionCodeOptionsLoading: false,

      LomCode: "",
      LomCodeOptions: [],
      LomCodeOptionsLoading: false,

      MonthCodeRules: [(v) => !!v || "Month is required"],
      MonthCode: "",
      MonthCodeOptions: [],
      MonthCodeOptionsLoading: false,

      ExcelDownloadFlag: false,
      ExcelLoadingFlag: false,
      ExcelFileFlag: false,

      Modules: {},

      tableData1: [],
      tableColumns1: [],
      tableOptions1: [],
      selected: [],
      search: "",
      CurrentYearId: 0,
      RoleId: "",

      PageInfo: {},
    };
  },
  mounted() {},
  watch: {
    PageInfo: function () {
      console.log("watch PageInfo");
      this.LoadingFlag = false;
      var tr = this.PageInfo;
      console.log({ tr });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      if (n1 > 0) {
        this.PageTitle = tr.PageTitle;
        this.PageDescription = tr.PageDescription;

        var CurrentJciYearId = parseInt(tr.CurrentJciYearId);
        console.log({ CurrentJciYearId });
        this.JciYearCode = CurrentJciYearId;

        var YearName = parseInt(tr.CurrentJciYearName);
        console.log({ YearName });
        this.YearName = YearName;

        var NextJciYearId = parseInt(tr.NextJciYearId);
        console.log({ NextJciYearId });
        this.NextYearCode = NextJciYearId;

        var NextJciYearName = parseInt(tr.NextJciYearName);
        console.log({ NextJciYearName });
        this.NextYearName = NextJciYearName;

        this.CurrentYearId = CurrentJciYearId;

        var RoleId = this.$session.get("RoleId");
        RoleId = RoleId == (null || undefined) ? "" : RoleId;
        // RoleId = 4;
        this.GenerateMrfButtonFlag = RoleId == 2 ? true : false;
        this.UploadMrfButtonFlag = RoleId == 2 ? true : false;

        this.pageData();
      }
    },
    AreaCodeOptions: function () {
      console.log("watch AreaCodeOptions");
      this.AreaCodeOptionsLoading = false;
      this.LoadingFlag = false;
    },
    ZoneCodeOptions: function () {
      console.log("watch ZoneCodeOptions");
      this.ZoneCodeOptionsLoading = false;
    },
    RegionCodeOptions: function () {
      console.log("watch RegionCodeOptions");
      this.RegionCodeOptionsLoading = false;
    },
    LomCodeOptions: function () {
      console.log("watch LomCodeOptions");
      this.LomCodeOptionsLoading = false;
    },
    MonthCodeOptions: function () {
      console.log("watch MonthCodeOptions");
      this.MonthCodeOptionsLoading = false;
    },
  },
  methods: {
    refreshPageData() {
      console.log("refreshPageData called");
      this.LoadingFlag = true;
      var url1 = "api/menu-details/show";
      var condition1 = {
        UserInterface: 1,
        Module: "mrf",
        Action: "report_1",
      };
      this.getPageDetails("PageInfo", {}, url1, condition1);
    },
    pageData() {
      console.log("pageData called");
      this.getAreaCodeOptions();
      this.getMonthCodeOptions();
    },
    searchForm() {
      console.log("searchForm is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var message = "";

        var YearCode = this.JciYearCode;
        console.log({ YearCode });

        var AreaCode = this.AreaCode;
        console.log({ AreaCode });

        var ZoneCode = this.ZoneCode;
        console.log({ ZoneCode });

        var RegionCode = this.RegionCode;
        console.log({ RegionCode });

        var LomCode = this.LomCode;
        console.log({ LomCode });

        var MonthCode = this.MonthCode;
        console.log({ MonthCode });

        if (YearCode != "" && MonthCode != "" && ZoneCode != "") {
          this.LoadingFlag = true;
          this.tableData1 = [];
          this.ExcelDownloadFlag = false;
          this.ExcelFileFlag = false;
          this.Modules = {};

          var server_url = companyConfig.apiURL;
          var token = this.$session.get("token");
          token = token == (null || undefined) ? 0 : token;
          var add_url = server_url + "api/jci-events/month-list";
          var upload = {
            UserInterface: 2,
            Year: YearCode,
            Area: AreaCode,
            Zone: ZoneCode,
            Region: RegionCode,
            Lom: LomCode,
            Month: MonthCode,
          };
          console.log("upload=" + JSON.stringify(upload));
          console.log(
            "server_url=" +
              server_url +
              ", token=" +
              token +
              ", add_url=" +
              add_url
          );

          const thisIns = this;
          var output = "";
          var records = {};
          var successTxt = "";
          var errorTxt = "";
          var flag = 0;

          this.$http({
            url: add_url,
            method: "POST",
            data: upload,
            headers: {
              "Content-Type": "application/json",
              Authorization: "Bearer " + token,
            },
            onUploadProgress: function (progressEvent) {
              this.uploadPercentage = parseInt(
                Math.round((progressEvent.loaded / progressEvent.total) * 100)
              );
            }.bind(this),
          })
            .then(function (response) {
              console.log("response=" + JSON.stringify(response));

              // thisIns.pageLoadingOff()
              thisIns.LoadingFlag = false;

              output = response.data.output;
              flag = response.data.flag;
              output = response.data.output;
              records = response.data.records;
              console.log("output=" + output + ", flag=" + flag);
              successTxt = response.data.success;
              errorTxt = response.data.error;
              console.log(
                "successTxt=" + successTxt + ", errorTxt=" + errorTxt
              );

              console.log({ records });

              if (flag == 1) {
                var options = records.TableOptions;
                console.log({ options });

                thisIns.tableColumns1 = records.TableHeader;
                thisIns.tableOptions1 = options;
                thisIns.ExcelDownloadFlag = options.ExcelDownloadButtonFlag;
                thisIns.tableData1 = records.TableData;
                thisIns.toast("success", output);
              } else {
                thisIns.toast("error", output);
              }
            })
            .catch(function (error) {
              console.log("error=" + error);
              thisIns.LoadingFlag = false;
            });
        } else {
          if (YearCode == "") {
            message += "Year should not be empty. ";
          }
          if (LomCode == "") {
            message += "Month should not be empty. ";
          }
          this.toast("error", message);
        }
      }
    },
    sendEmailAlert(id, tr) {
      console.log("sendEmailAlert called");
      console.log({ id });
      console.log({ tr });

      var message = "";

      var LomCode = tr.LomCode;
      console.log({ LomCode });

      if (LomCode > 0) {
        var mailText = "";
        switch (id) {
          case 1:
            mailText = "Appreciation";
            break;
          case 2:
            mailText = "Remainder";
            break;

          default:
            break;
        }
        var htmlTxt = "";
        htmlTxt +=
          "<br/> You are goind to send <b>" +
          mailText +
          " E-mail </b> to <b> " +
          tr.LomName +
          "</b>";

        Swal.fire({
          title: "Are you sure?",
          text: "",
          icon: "warning",
          html: htmlTxt,
          showCancelButton: true,
          confirmButtonText: `Save`,
          confirmButtonColor: "#3085d6",
          cancelButtonText: `Cancel`,
          cancelButtonColor: "#d33",
        }).then((result) => {
          /* Read more about isConfirmed, isDenied below */
          if (result.isConfirmed) {
            this.sendEmailToLom(id, tr);
          } else {
            this.sweetAlert("error", "You cancelled the process", true);
          }
        });
      } else {
        if (LomCode == "") {
          message += "LOM should not be empty. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    sendEmailToLom(id, tr) {
      console.log("sendEmailToLom is called");

      var message = "";

      console.log({ id });
      console.log({ tr });

      var LomCode = tr.LomCode;
      console.log({ LomCode });

      if (LomCode > 0) {
        this.OverlayLoadingFlag = true;
        this.OverlayLoadingText =
          "Please wait, sending email processed. Loading time may vary depending on your internet speed.";

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/lom-send-email";
        var upload = {
          UserInterface: 1,
          Year: tr.YearCode,
          Month: tr.MonthId,
          Lom: LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            console.log("output=" + output + ", flag=" + flag);

            if (flag == 1) {
              var options = records.TableOptions;
              console.log({ options });

              thisIns.sweetAlert("success", output, true);
            } else {
              thisIns.sweetAlert("error", output, false);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.LoadingFlag = false;
          });
      } else {
        if (LomCode == "") {
          message += "LOM should not be empty. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    generateManualMrf() {
      console.log("generateManualMrf is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var message = "";

        var YearCode = this.JciYearCode;
        console.log({ YearCode });

        var AreaCode = this.AreaCode;
        console.log({ AreaCode });

        var ZoneCode = this.ZoneCode;
        console.log({ ZoneCode });

        var RegionCode = this.RegionCode;
        console.log({ RegionCode });

        var LomCode = this.LomCode;
        console.log({ LomCode });

        var MonthCode = this.MonthCode;
        console.log({ MonthCode });

        if (
          YearCode != "" &&
          MonthCode != "" &&
          ZoneCode != "" &&
          LomCode != ""
        ) {
          var token = this.$session.get("token");
          token = token == (null || undefined) ? 0 : token;

          var server_url = companyConfig.apiURL;
          var add_url =
            server_url +
            "pdf/mrf-report/" +
            token +
            "/" +
            YearCode +
            "/" +
            MonthCode +
            "/" +
            LomCode;
          console.log({ add_url });
          window.open(add_url, "_blank");
        } else {
          if (YearCode == "") {
            message += "Year should not be empty. ";
          }
          if (MonthCode == "") {
            message += "Month should not be empty. ";
          }
          if (ZoneCode == "") {
            message += "Zone should not be empty. ";
          }
          if (LomCode == "") {
            message += "Zone should not be empty. ";
          }
          this.sweetAlert("error", message, false);
        }
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    updateLomMrf() {
      console.log("updateLomMrf is called");

      var validate1 = this.$refs.form1.validate();
      console.log("validate1=" + validate1);

      if (validate1) {
        var message = "";

        var YearCode = this.JciYearCode;
        console.log({ YearCode });

        var AreaCode = this.AreaCode;
        console.log({ AreaCode });

        var ZoneCode = this.ZoneCode;
        console.log({ ZoneCode });

        var RegionCode = this.RegionCode;
        console.log({ RegionCode });

        var LomCode = this.LomCode;
        console.log({ LomCode });

        var MonthCode = this.MonthCode;
        console.log({ MonthCode });

        if (
          YearCode != "" &&
          MonthCode != "" &&
          ZoneCode != "" &&
          LomCode != ""
        ) {
          var idx1 = this.MonthCodeOptions.map((e) => e.value).indexOf(
            MonthCode
          );
          console.log("idx1=" + idx1);
          var tr1 = idx1 >= 0 ? this.MonthCodeOptions[idx1] : {};
          console.log({ tr1 });
          var MonthName = tr1.text;
          console.log({ MonthName });

          var idx2 = this.LomCodeOptions.map((e) => e.value).indexOf(LomCode);
          console.log("idx2=" + idx2);
          var tr2 = idx2 >= 0 ? this.LomCodeOptions[idx2] : {};
          console.log({ tr2 });
          var LomName = tr2.text;
          console.log({ LomName });

          this.UploadMrfPageTitle =
            "Upload MRF for " + MonthName + " " + this.YearName + " " + LomName;

          this.MrfDetail = {
            YearCode: YearCode,
            MonthCode: MonthCode,
            MonthName: MonthName,
            ZoneCode: ZoneCode,
            LomCode: LomCode,
            LomName: LomName,
          };
          this.UploadMrfFlag = true;
        } else {
          if (YearCode == "") {
            message += "Year should not be empty. ";
          }
          if (MonthCode == "") {
            message += "Month should not be empty. ";
          }
          if (ZoneCode == "") {
            message += "Zone should not be empty. ";
          }
          if (LomCode == "") {
            message += "Zone should not be empty. ";
          }
          this.sweetAlert("error", message, false);
        }
      } else {
        var message = "";
        if (!validate1) {
          message += "Kindly fill the required fields. ";
        }
        this.sweetAlert("error", message, false);
      }
    },
    generateReport(index, tr) {
      console.log("generateReport is called");
      var message = "";
      console.log({ index });
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });
      // n1 = 0;
      if (n1 > 0) {
        var filepath =
          "http://192.168.1.101/projects/jci_india_erp/jci_india_erp_public/lib/assets/export/mrf/2021/848/jci_months.csv";
        console.log({ filepath });
        this.tableData1[index].FilePath = "";
        this.tableData1[index].FileDownloadFlag = false;
        this.tableData1[index].FilePreviewFlag = false;
        this.tableData1[index].LastUpdatedAtTxt = "";
        this.OverlayLoadingFlag = true;
        this.OverlayLoadingText =
          "Please wait, your Report is getting generated. Loading time may vary depending on the number of events you have uploaded.";

        var server_url = companyConfig.apiURL;
        var token = this.$session.get("token");
        token = token == (null || undefined) ? 0 : token;
        var add_url = server_url + "api/jci-events/generate-monthly-report";

        var YearCode = this.JciYearCode;
        console.log({ YearCode });

        var LomCode = this.tableData1[index].LomCode;
        console.log({ LomCode });

        var MonthCode = this.MonthCode;
        console.log({ MonthCode });

        var upload = {
          UserInterface: 2,
          Year: tr.YearCode,
          Month: tr.MonthId,
          Lom: tr.LomCode,
        };
        console.log("upload=" + JSON.stringify(upload));
        console.log(
          "server_url=" +
            server_url +
            ", token=" +
            token +
            ", add_url=" +
            add_url
        );

        const thisIns = this;
        var output = "";
        var records = "";
        var successTxt = "";
        var errorTxt = "";
        var flag = 0;

        this.$http({
          url: add_url,
          method: "POST",
          data: upload,
          headers: {
            "Content-Type": "application/json",
            Authorization: "Bearer " + token,
          },
          onUploadProgress: function (progressEvent) {
            this.uploadPercentage = parseInt(
              Math.round((progressEvent.loaded / progressEvent.total) * 100)
            );
          }.bind(this),
        })
          .then(function (response) {
            console.log("response=" + JSON.stringify(response));

            // thisIns.pageLoadingOff()

            thisIns.OverlayLoadingFlag = false;

            output = response.data.output;
            flag = response.data.flag;
            output = response.data.output;
            records = response.data.records;
            console.log("output=" + output + ", flag=" + flag);
            successTxt = response.data.success;
            errorTxt = response.data.error;
            console.log("successTxt=" + successTxt + ", errorTxt=" + errorTxt);

            thisIns.tableData1[index].FileDownloadFlag =
              records.FileDownloadFlag;
            thisIns.tableData1[index].FilePreviewFlag =
              records.FileDownloadFlag;
            thisIns.tableData1[index].FilePath = records.FilePath;

            if (flag == 1) {
              thisIns.searchForm();
              thisIns.tableData1[index].FileDownloadFlag =
                records.FileDownloadFlag;
              thisIns.tableData1[index].FilePreviewFlag =
                records.FileDownloadFlag;
              thisIns.tableData1[index].FilePath = records.FilePath;
              thisIns.toast("success", output);
            } else {
              thisIns.toast("error", output);
            }
          })
          .catch(function (error) {
            console.log("error=" + error);
            thisIns.OverlayLoadingFlag = false;
          });
      } else {
        message = "Kindly select one month to generate report";
        this.toast("error", message);
      }
    },
    getAreaCodeOptions() {
      console.log("getAreaCodeOptions called");
      this.AreaCodeOptionsLoading = true;
      var selectbox1_source = "AreaCode";
      var selectbox1_destination = "AreaCodeOptions";
      var selectbox1_url = "api/year-wise-lom/area-options";
      var selectbox1_conditions_array = {
        UserInterface: 3,
        YearCode: this.JciYearCode,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getMonthCodeOptions() {
      console.log("getMonthCodeOptions called");
      this.MonthCodeOptionsLoading = true;
      var selectbox1_source = "MonthCode";
      var selectbox1_destination = "MonthCodeOptions";
      var selectbox1_url = "api/jci-month/options";
      var selectbox1_conditions_array = {
        UserInterface: 1,
      };
      this.getSelectBoxOptions(
        selectbox1_source,
        selectbox1_destination,
        selectbox1_conditions_array,
        selectbox1_url
      );
    },
    getZoneCodeOptions() {
      console.log("getZoneCodeOptions called");
      this.tableData1 = [];
      this.ZoneCode = "";
      this.RegionCode = "";
      this.LomCode = "";
      this.ZoneCodeOptions = [];
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var yearCode = this.JciYearCode;
      var areaCode = this.AreaCode;
      console.log("yearCode=" + yearCode + ", areaCode=" + areaCode);
      if (yearCode != "" && areaCode != "") {
        this.ZoneCode = "";
        this.ZoneCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "ZoneCode";
        var selectbox1_destination = "ZoneCodeOptions";
        var selectbox1_url = "api/year-wise-zone-options";
        var selectbox1_conditions_array = {
          UserInterface: 6,
          AreaCode: areaCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getRegionCodeOptions() {
      console.log("getRegionCodeOptions called");
      this.tableData1 = [];
      this.RegionCode = "";
      this.LomCode = "";
      this.RegionCodeOptions = [];
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var AreaCode = this.AreaCode;
      var ZoneCode = this.ZoneCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", AreaCode=" +
          AreaCode +
          ", ZoneCode=" +
          ZoneCode
      );
      if (YearCode != "" && AreaCode != "" && ZoneCode != "") {
        this.RegionCode = "";
        this.RegionCodeOptionsLoading = true;
        this.tableData2 = [];
        var selectbox1_source = "RegionCode";
        var selectbox1_destination = "RegionCodeOptions";
        var selectbox1_url = "api/year-wise-lom/region-options";
        var selectbox1_conditions_array = {
          UserInterface: 3,
          YearCode: YearCode,
          AreaCode: AreaCode,
          ZoneCode: ZoneCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      }
    },
    getLomCodeOptions() {
      console.log("getLomCodeOptions called");
      this.tableData1 = [];
      this.LomCode = "";
      this.LomCodeOptions = [];

      var YearCode = this.JciYearCode;
      var ZoneCode = this.ZoneCode;
      var RegionCode = this.RegionCode;
      console.log(
        "YearCode=" +
          YearCode +
          ", ZoneCode=" +
          ZoneCode +
          ", RegionCode=" +
          RegionCode
      );
      if (YearCode != "" && ZoneCode != "" && RegionCode != "") {
        this.LomCodeOptionsLoading = true;
        var selectbox1_source = "LomCode";
        var selectbox1_destination = "LomCodeOptions";
        var selectbox1_url = "api/year-wise-lom-options";
        var selectbox1_conditions_array = {
          UserInterface: 4,
          YearCode: YearCode,
          ZoneCode: ZoneCode,
          RegionCode: RegionCode,
        };
        this.getSelectBoxOptions(
          selectbox1_source,
          selectbox1_destination,
          selectbox1_conditions_array,
          selectbox1_url
        );
      } else {
        var message = "";
        if (YearCode == "") {
          message += "Year should not be empty. ";
        }
        if (ZoneCode == "") {
          message += "Zone should not be empty. ";
        }
        if (RegionCode == "") {
          message += "Region should not be empty. ";
        }
        this.alert = {
          flag: true,
          color: "error",
          message: message,
        };
      }
    },
    hidePreviewRecordPrompt() {
      console.log("hidePreviewRecordPrompt called");
      this.previewRecordPrompt = false;
    },
    hideUploadMrfPrompt() {
      console.log("hideUploadMrfPrompt called");
      this.UploadMrfFlag = false;
      this.MrfDetail = {};
      this.searchForm();
    },
    previewData(tr) {
      console.log("previewData called");
      var message = "";
      var n1 = Object.keys(tr).length;
      console.log({ n1 });
      console.log({ tr });
      if (n1 > 0) {
        var FilePath = tr.FilePath;
        console.log({ FilePath });
        var MonthName = tr.MonthName;
        console.log({ MonthName });
        if (FilePath != "") {
          this.previewRecordPrompt = true;
          this.DocumentFilePath = FilePath;
          this.PreviewPageTitle = MonthName + " MRF Preview";
        } else {
          message = "File should not be empty.";
          this.toast("error", message);
        }
      } else {
        message = "Kindly select one record to preview";
        this.toast("error", message);
      }
    },
  },
  beforeMount() {
    this.refreshPageData();
  },
};
</script>
<style lang="scss">
/* This is for documentation purposes and will not be needed in your application */

@import "~vue-tabulator/dist/scss/bootstrap/tabulator_bootstrap4";

#mrf-report {
  .loading-text {
    font-size: 18px;
  }
  .v-btn--floating {
    position: relative;
  }
  .v-speed-dial--bottom {
    bottom: 12%;
  }
  .v-speed-dial--right {
    right: 5%;
  }
  .v-data-table > .v-data-table__wrapper > table > thead > tr > th {
    font-size: 15px;
  }
  .v-data-table > .v-data-table__wrapper > table > tbody > tr > td {
    font-size: 14px;
  }
}
</style>